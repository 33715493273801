.wrapper {
    padding: 0 3rem 0 3rem;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 7rem;
    flex-direction: column;
    gap: 2rem;
    position: relative;
}

.heading {
    align-self: start;
}

.heading>* {
    font-size: 2rem;
    font-weight: bold;
}

.heading span:nth-of-type(2) {
    color: var(--orange);
}

.tBlur1 {
    left: 38rem !important;
    top: 16rem !important;
}

.tBlur2 {
    top: 9rem !important;
    left: 0 !important;
}

.testimonial {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: 2rem;
    width: 30rem;
    height: 12rem;
    /* styles same as work.css */
    background: rgba(255, 255, 255, 0.26);
    border: 7px solid var(--blueCard);
    box-shadow: var(--boxShadow);
    border-radius: 20px;
}

.testimonial>img {
    width: 6rem;
    height: 6rem;
    border-radius: 100%;
}

.testimonial>span {
    color: var(--gray);
}

@media screen and (max-width: 480px) {
    .wrapper {
        padding: 0;
    }

    .testimonial>img {
        width: 70px;
        height: 70px;
    }
}