.footer {
    display: flex;
    align-items: center;
    margin: -0.5rem -3.5rem;
    position: relative;
    margin-top: -7rem;
}

.content {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin-top: 4rem;
    gap: 4rem;
    color: white;
}

.icons {
    display: flex;
    gap: 2rem;
}

.icons>* {
    font-size: 3rem;
    color: white;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.icons>*:hover {
    color: #222;
}

@media screen and (max-width: 480px) {
    .content {
        transform: scale(.5);
    }
}