.works {
    padding: 0 3rem 0 3rem;
    display: flex;
    height: 90vh;
    margin-top: 4rem;

    /* scroll */
    align-items: center;
}

/* left side */
.awesome {
    display: flex;
    flex-direction: column;
    position: relative;
    /* gap: 20px; */
}

.awesome> :nth-child(1) {
    color: var(--black);
    font-size: 2.5rem;
    font-weight: bold;
}

.awesome> :nth-child(2) {
    color: var(--orange);
    font-size: 2.5rem;
    font-weight: bold;
}

.awesome> :nth-child(3) {
    color: var(--gray);
    font-size: 14px;
    margin-top: 1rem;
}

.sButton {
    width: 8rem;
    height: 2rem;
    margin-top: 1rem;
}

/* right side */
.right {
    position: relative;
}

.mainCircle {
    left: 9rem;
    position: relative;
    width: 18rem;
    height: 18rem;
    border-radius: 100%;
    box-shadow: var(--smboxShadow);
    top: 2rem;
    background: white;
    /* darkMode */
    z-index: 2;
}

.mainCircle>* {
    position: absolute;
}

.mainCircle> :nth-child(1) {
    top: -3rem;
    left: 6rem;
}

.mainCircle> :nth-child(2) {
    left: -3rem;
    top: 5rem;
}

.mainCircle> :nth-child(3) {
    left: 6rem;
    top: 5rem;
}

.mainCircle> :nth-child(4) {
    left: 15rem;
    top: 5rem;
}

.mainCircle> :nth-child(5) {
    left: 6rem;
    top: 13rem;
}

.secondCircle {
    width: 6rem;
    height: 6rem;
    position: absolute;
    border-radius: 100%;
    border: 5px solid #ebebeb;
    display: flex;
    align-items: center;
    justify-content: center;
    background: "none";
    box-shadow: var(--smboxShadow);
    background: white;
}

.secondCircle>img {
    transform: scale(0.6);
}

.backCircle {
    position: absolute;
    height: 13rem;
    width: 13rem;
    border-radius: 100%;
    /* darkMode */
    z-index: 1;
}

.blueCircle {
    background: #1949b8;
    left: 18rem;
    top: 0rem;
}

.yellowCircle {
    background: #f5c32c;
    left: 18rem;
    top: 8rem;
}

/* blur */
.sBlur2 {
    left: 14rem;
    top: 8rem;
}

.sBlur1 {
    top: 13rem !important;
    left: -18rem !important;
}

@media screen and (max-width: 480px) {
    .works {
        flex-direction: column;
        height: 55rem;
        padding: 0;
        gap: 5rem;
    }

    .right {
        display: flex;
        align-items: flex-start;
        justify-content: start;
        transform: scale(0.7);
    }

    .mainCircle {
        position: static;
    }

    .backCircle {
        left: 8rem !important;
    }

}