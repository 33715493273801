:root {
    --yellow: #F5C32C;
    --orange: #FCA61F;
    --black: #242D49;
    --gray: #788097;
    --blueCard: #DDF8FE;
    --purple: rgb(238 210 255);
    --boxShadow: 0px 19px 60px rgb(0 0 0 / 8%);
    --orangeCard: rgba(252, 166, 31, 0.45);
    --smboxShadow: -79px 51px 60px rgba(0, 0, 0, 0.08);
}

@font-face {
    font-family: Gumela;
    src: url('../src/assets/fonts/Gumela\ Regular.otf');
}

* {
    font-family: "Gumela";
    font-size: 20px;
    font-weight: normal;
}

body {
    margin: 0;
}

.App {
    padding: 0.5rem 3.5rem;
    overflow: hidden;
    color: var(--black);
}

.button {
    background: linear-gradient(180deg, #fdc50f 26.71%, #fb982f 99.36%);
    box-shadow: 0px 20px 24px 3px rgba(251, 161, 40, 0.42);
    border-radius: 34px;
    border: none;
    color: white;
    font-size: 16px;
    padding: 11px 26px 11px 26px;
    border: 1px solid transparent;
    transition: all 0.3s ease-in-out;
}

.button:hover {
    background: white;
    cursor: pointer;
    border: 1px solid var(--orange);
    color: var(--orange);
}

.blur {
    position: absolute;
    width: 22rem;
    height: 14rem;
    border-radius: 50%;
    background: #edd0ff;
    z-index: -9;
    top: -18%;
    left: 56%;
    filter: blur(72px);
}

@media screen and (max-width: 480px) {
    .App {
        padding: 0.5rem 1rem;
    }
}