/* slider */
.swiper {
    width: 100%;
    height: 70%;
}

.swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
}

.swiper-pagination-bullet-active {
    background: var(--orange);
}