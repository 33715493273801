.floatingDiv {
    display: flex;
    justify-content: space-around;
    background: white;
    box-shadow: var(--boxShadow);
    border-radius: 17px;
    align-items: center;
    padding: 0 28px 0 0;
    height: 4.5rem;
}

.floatingDiv>img {
    transform: scale(0.4);
}

.floatingDiv>span {
    font-family: sans-serif;
    font-size: 16px;
}

@media screen and (max-width: 480px) {
    .floatingdiv:nth-of-type(1) {
        /* display: none; */
        top: -7rem !important;
        ;
    }
}