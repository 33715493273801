.hero {
    display: flex;
    height: 77vh;
    margin-top: 6rem;
}

.left {
    display: flex;
    position: relative;
    flex: 1;
    flex-direction: column;
    gap: 2rem;
}

.name {
    display: flex;
    flex-direction: column;
}

.name>:nth-child(1) {
    color: var(--black);
    font-weight: bold;
    font-size: 3rem;
}

.name>:nth-child(2) {
    color: var(--orange);
    font-weight: bold;
    font-size: 3rem;
}

.name>:nth-child(3) {
    font-weight: 100;
    font-size: 14px;
    color: var(--gray);
    margin-top: 10px;
}

.hButton {
    width: 6rem;
    height: 2rem;
}

.icons {
    margin-top: 3rem;
    display: flex;
    gap: 0rem;
}

.icons>* {
    transform: scale(0.5);
}

.icons>*:hover {
    cursor: pointer;
}

.right {
    flex: 1;
    position: relative;
}

.right>* {
    position: absolute;
    z-index: 1;
}

.right>:nth-child(1) {
    transform: scale(0.69);
    left: -15%;
    top: -9%;
}

.right>:nth-child(2) {
    transform: scale(0.67);
    left: -3%;
    top: -4.6rem;
}

.right>:nth-child(3) {
    transform: scale(1.4);
    left: 28%;
}

.right>:nth-child(4) {
    transform: scale(0.62);
    left: -24%;
    top: -19%;
    border-radius: 50%;
    padding: 0;
}

.div1 {
    top: -4%;
    left: 68%;
}

.div2 {
    top: 18rem;
    left: 0;
}

.blur1 {
    background: rgb(238, 210, 255);
    z-index: -9;
}

.blur2 {
    background: #c1f5ff;
    top: 17rem;
    width: 21rem;
    height: 11rem;
    left: 9rem;
    z-index: -9;
}

@media screen and (max-width: 480px) {
    .hero {
        flex-direction: column;
        gap: 7rem;
        height: 64rem;
    }

    .right {
        transform: scale(0.8);
        left: -3rem;
    }

    .right .blur {
        display: none !important;
    }

}